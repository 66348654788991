export const Preview = () => {
    return (
        <div className="lg:px-1 overflow-hidden lg:pt-8 py-8 pb-16 bg-black">
            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                    D&Dream Property Group was established in the year 2017,
                    October 1st and constantly expanding the team nationwide to
                    be the leading property agency in Malaysia. Through the
                    years of hard work and dedication, D&Dream Property Group
                    has obtained recognition from the market:
                </div>

                <img src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-02_icF9qp5Yn.png?updatedAt=1719653101749" alt="" />
            </div>

            <div
                className="lg:px-3 lg:mb-16 my-3 py-2 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    Vision
                </div>
                <div className="md:pt-1 lg:pt-4 py-1">
                    To be the largest property agent in Malaysia, emphasizing on
                    giving profit and power while holding on to the principle of
                    equality.
                </div>
            </div>

            <div
                className="lg:px-3 lg:mb-16 my-3 py-2 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                     <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    Mission
                </div>
                <div className="md:pt-1 lg:pt-4 py-1">
                    To provide sufficient properties to sell! To ensure 100%
                    fast commission is paid without delay! To enjoy life to the
                    fullest!
                </div>
            </div>

            <hr />
            <div
                className="lg:px-3 lg:mb-8 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    BENEFITS
                </div>
                <div className="grid lg:grid-cols-3 lg:p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground font-semibold">
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-28"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-04_hJ3gF2l5j.png?updatedAt=1719652161356"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-4 py-1">
                            Car Subsidy Up To RM12,000
                        </div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-28"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-06_sO2B80vC8a.png?updatedAt=1719652630573"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-4 py-1">
                            3 Days 100% Comm.
                        </div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-28"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-07_x-Hsv0SB3.png?updatedAt=1719652630495"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-4 py-1">
                          Oversea Incentive Trips
                        </div>
                    </div>
                </div>

                <div className="grid lg:grid-cols-3 lg:p-8 w-full h-full px-8 rounded-lg block greyOpaqueBackground font-semibold">
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-28"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-08_A21G1Gkya.png?updatedAt=1719652630522"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-4 py-1">
                            Exclusive Projects
                        </div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-28"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-09_oU8yS8npV.png?updatedAt=1719652630497"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-4 py-1">
                            Group Profit Sharing
                        </div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-28"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-10_uK_Z7NC9Zu.png?updatedAt=1719652630660"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-4 py-1">
                          1-1 Leadership & Team Trainings
                        </div>
                    </div>
                </div>

                <div className="grid lg:grid-cols-2 lg:p-8 w-full h-full px-6 rounded-lg block greyOpaqueBackground font-semibold">
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-28"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-11_MxdciLnBJ.png?updatedAt=1719652630498"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-4 py-1">
                            Image Branding Training
                        </div>
                    </div>
                    <div className="block">
                        <div className="flex justify-center">
                            <img
                                className="h-full w-28"
                                src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-12_oF_qf_j_l.png?updatedAt=1719652630522"
                                alt="ptp1comm"
                            />
                        </div>
                        <div className="md:pt-1 lg:pt-4 py-1">
                            Relax Co-Working Space Provided
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
