export const TeamContent = () => {
    return (
        <div className="lg:px-1 overflow-hidden lg:pt-8 py-8 pb-16 bg-black">
            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                    In D&D, we have appointed best of the best trainers who has been performing well in the market to train and deliver the knowledge of real estate selling technique for everyone.
                </div>

                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    D&D TRAINERS
                </div>
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-21_ZLZJpbmm4.png?updatedAt=1719761929806"
                    alt=""
                />
            </div>

            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >


                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    D&D CLASSES
                </div>
                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                    Weekly intensive calsses to educate our agents with real-time real estate knowledge to master the market.
                </div>

                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                Calling Skill • Closing Skill • How To Get Potential Leads How To Be The Star In Real Estate Market & etc.
                </div>
            </div>



            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-23_o55LChf5b.png?updatedAt=1719809400687"
                    alt=""
                />
            </div>

            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-24_wMoYLw79B.png?updatedAt=1719809400610"
                    alt=""
                />
            </div>

            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-22_D7T3pZvMRl.png?updatedAt=1719809400606"
                    alt=""
                />
            </div>

            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:text-xl px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <img
                    src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-25_nBGnnpgcR.png?updatedAt=1719809400615"
                    alt=""
                />
            </div>

            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                   With all the skills and knowledge delivered, we have successfully:
                </div>

                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                   SOLD OVER
                </div>
                <div className="md:pt-1 py-1 mb-4 text-7xl primalbrown">
                   15,080
                </div>
                <div className="md:pt-1 py-1 mb-4">
                   UNITS IN 2023
                </div>
            </div>
            
            <hr />

            <div
                className="lg:px-3 lg:mb-4 my-3 py-2 lg:pb-8 lg:text-xl px-8 text-center tracking-tighter text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 mb-4">
                   ACHIEVED GDV OF
                </div>
                <div className="md:pt-1 py-1 mb-4 text-4xl lg:text-7xl primalbrown">
                   7,548,580,111
                </div>
                <div className="md:pt-1 py-1 mb-4">
                   IN 2023
                </div>
            </div>


           
        </div>
    );
};
