import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Image = styled.img`
    object-fit: cover;
    opacity: 0.4;
    width: 100vw;
    ${mobile({ height: "100%" })}
`;

const Header = styled.div`
    height: 60vh;
    width: 100vw;
    background: black;
    position: relative;
    overflow: hidden;
    display: none;
    ${mobile({ height: "50vh", display: "block" })}
`;

const Title = styled.h1`
    text-transform: uppercase;
    padding: 1rem;
    font-family: FuturaPT-Bold;
    font-size: 4rem;
    margin: 50px 0;
    color: white;
    ${mobile({ margin: "1rem", textAlign: "center", fontSize: "2rem" })}
    ${tablet({ margin: "1rem", textAlign: "center", fontSize: "3rem" })}
`;

const Section = styled.div`
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: "Roboto", sans-serif;
    ${mobile({ top: "50%" })}
`;

const BannerOverlay = (imageUrl) => {
    return (
        <Header>
            <Image src={imageUrl.imageUrl} alt="About"></Image>{" "}
            <Section>
                <Title>{imageUrl.title}</Title>
            </Section>
        </Header>
    );
};

export default BannerOverlay;
