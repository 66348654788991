import {
    MailOutline,
    Phone,
    Room,
} from "@material-ui/icons";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";
import { Link } from "react-router-dom";

const Container = styled.div`
    padding: 20px 5rem;
    display: flex;
    background-size: cover;
    background-position: center;
    font-family: "FuturaPT-Demi";
    ${mobile({ flexDirection: "column", padding: "0px" })}
    ${tablet({ flexDirection: "column", padding: "0px" })}
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    ${mobile({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
    ${tablet({
        padding: "2rem 3rem",
        justifyContent: "center",
        alignItems: "center",
    })}
`;

const Logo = styled.img`
    max-height: 8vh;
    max-width: 22vh;
    ${mobile({ maxHeight: "15vh" })};
    ${tablet({ maxHeight: "15vh" })};
`;
const Desc = styled.p`
    margin: 20px 0px;
`;

const SocialContainer = styled.div`
    display: flex;
`;

const SocialIcon = styled.a`
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
`;

const Center = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const Title = styled.h3`
    margin-bottom: 30px;
    ${mobile({ display: "none" })}
    ${tablet({ display: "none" })}
`;

const List = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    flex-wrap: wrap;
`;

const Right = styled.div`
    flex: 1;
    padding: 10px 20px;
    ${mobile({ color: "black", padding: "3rem" })}
    ${tablet({ color: "black", padding: "3rem" })}
`;

const ContactItem = styled.div`
    margin-bottom: 20px;
    display: flex;
    align-items: center;
`;

const CopyrightContainer = styled.div`
font-family: Molde-Expanded-Bold;
    background: black;
    color: white;
    padding: 20px 5rem;
    display: flex;
    justify-content: center;
    ${mobile({ flexDirection: "column", padding: "20px 3rem" })}
    ${tablet({ flexDirection: "column", padding: "20px 3rem" })}
`;

const Copyright = styled.div`
    text-align: center;
    font-size: 1rem;
    ${mobile({ fontSize: "0.8rem" })}
    ${tablet({ fontSize: "0.9rem" })}
`;

export const Footer = () => {
    return (
        < div className="bg-white">
            <Container>
                <Left>
                    <Logo src="https://ik.imagekit.io/dnddecpho/dndream/D_D_NewWebsiteIcon-01_f7I9Dz6w1.png?updatedAt=1706110071243"></Logo>
                    <Desc>
                        Embark on an exciting career journey with D&D Property
                        Group as we broaden our opportunities.
                    </Desc>
                    <SocialContainer>
                        <SocialIcon
                            href="http://facebook.com/dndpropertygrp"
                            target="blank"
                            color="3B5999"
                        >
                             <img src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-13_xd3LAOH6T.png?updatedAt=1719654780865" alt="OMC" />
                        </SocialIcon>
                        <SocialIcon
                            href="http://instagram.com/dnd_propertygroup"
                            target="blank"
                            color="E4405F"
                        >
                              <img src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-14_YpkotnPUz.png?updatedAt=1719654780841" alt="EMC" />
                        </SocialIcon>
                    </SocialContainer>
                </Left>
                <Center>
                    <Title style={{fontSize: "1.2rem", fontFamily: "Molde-Expanded-Regular"}}>Menu</Title>
                    <List>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/about"
                        >
                            ABOUT
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/team"
                        >
                            TEAM
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/training"
                        >
                            TRAINING
                        </Link>
                        {/* <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/login"
                        >
                            GALLERY
                        </Link>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",

                                marginBottom: "10px",
                            }}
                            to="/login"
                        >
                            LOCATION
                        </Link> */}
                    </List>
                </Center>
                <Right>
                <Title style={{fontSize: "1.2rem", fontFamily: "Molde-Expanded-Regular"}}>Contact</Title>
                    <ContactItem>
                        <Room style={{ marginRight: "10px" }} /> D&Dream
                        Properties Sdn Bhd, G-22, Boulevard 28, Jalan Pandan
                        Perdana 3/10, Pandan Perdana, 55300 Kuala Lumpur
                    </ContactItem>
                    <ContactItem>
                        <Phone style={{ marginRight: "10px" }} /> +6 03 9202
                        5937
                    </ContactItem>
                    <ContactItem>
                        <MailOutline style={{ marginRight: "10px" }} />{" "}
                        dndpropertygrp@gmail.com
                    </ContactItem>
                </Right>
            </Container>
            <CopyrightContainer>
                <Copyright>
                    © 2024 D&DREAM PROPERTIES SDN. BHD.
                </Copyright>
            </CopyrightContainer>
        </div>
    );
};
