import  {Mission}  from "../components/pages/about/Mission";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import BannerOverlay from "../components/BannerOverlay";
import styled from "styled-components";
import { mobile, tablet } from "../responsive";

const Image = styled.img`
    width: 100vw;
    ${mobile({ height: "30vh" })}
    ${tablet({ height: "100%" })}
`;

const About = () => {
    return (
        <div  className="top-0" style={{background: 'url("https://dndpropertygrp.com/wp-content/uploads/2020/04/DD_ImagesForDEv13.png")', backgroundSize: "cover" ,backgroundPosition: "center" }}>
            <Header />
            <BannerOverlay
                imageUrl="https://ik.imagekit.io/dnddecpho/dndream/WhatsApp%20Image%202023-11-21%20at%2017.34.00_da76a3a1_tUHJcMYi0.jpg?updatedAt=1706109036662"
                title="About Us"
            ></BannerOverlay>
                       <Image src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-15_LsMwLmh0U.gif?updatedAt=1719656047744"alt="About"></Image>{" "}
            <Mission />
            <div
                className="lg:px-3 my-3 py-2 px-8 text-center text-white mx-auto max-w-3xl"
                style={{ fontFamily: "Molde-Expanded-Semibold" }}
            >
                <div className="md:pt-1 lg:pt-10 py-1 text-3xl lg:text-4xl uppercase primalbrown">
                    OUR PARTNERS
                </div>
            </div>

            <Image src="https://ik.imagekit.io/dnddecpho/dndream/D&D_WebsiteIcons-17_TBOItTZQB.gif?updatedAt=1719760888630"alt="Partners"></Image>{" "}
            <Footer />
        </div>
    );
}

export default About;
