import { Link } from "react-router-dom";
import styled from "styled-components";
import { List, Close } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { mobile, tablet } from "../responsive";

const Container = styled.div``;

const MenuContainer = styled.div`
    display: none;
    ${mobile({
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        background: "black",
        color: "white",
    })}
    ${tablet({
        display: "flex",
        alignItems: "center",
        padding: "10px 15px",
        background: "black",
        color: "white",
    })}
`;

const Dropddown = styled.div`
    padding-top: 15px;
    height: 100vh;
    color: white;
    background: black;
    display: flex;
    flex-direction: column;
    z-index: 99999;
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Logo = styled.img`
    width: 80%;
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
`;

const Center = styled.div`
    font-size: 1.5rem;
    cursor: pointer;
    font-family: FuturaPT-Bold;
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
`;

const MobileNavbar = () => {
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        if (showMenu === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [showMenu]);
    return (
        <>
            <Container>
                {" "}
                <MenuContainer>
                    {" "}
                    <Left>
                        {" "}
                        {showMenu ? (
                            <Close
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            ></Close>
                        ) : (
                            <>
                                <List
                                    onClick={() => {
                                        setShowMenu(true);
                                    }}
                                ></List>
                            </>
                        )}
                    </Left>
                    <Center>
                        <Link
                            style={{
                                textDecoration: "none",
                                color: "black",
                            }}
                            to="/"
                        >
                            <LogoContainer>
                                <Logo
                                    src={
                                        "https://ik.imagekit.io/dnddecpho/dndream/DD_LogoGold-300x53_9YWAdjFzX.png?updatedAt=1706110249831"
                                    }
                                ></Logo>
                            </LogoContainer>
                        </Link>
                    </Center>
                    <Right></Right>
                </MenuContainer>
                {showMenu && (
                    <Container>
                        <Dropddown>
                            {" "}
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Home
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/about"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                About Us
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/training"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Training
                            </Link>
                            <Link
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                to="/team"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Team
                            </Link>
                            <a
                                style={{
                                    textDecoration: "none",
                                    padding: "20px",
                                }}
                                href="https://www.dnduser.com"
                                onClick={() => {
                                    setShowMenu(false);
                                }}
                            >
                                Login
                            </a>
                        </Dropddown>
                    </Container>
                )}
            </Container>
        </>
    );
};

export default MobileNavbar;
